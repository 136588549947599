<template>
  <div class="inner-section">
    <card>
      <!-- search section start -->
      <template v-slot:searchHeaderTitle>
        <h4 class="card-title">{{$t('tradeTariffReport.concern_person_report')}}</h4>
      </template>
      <template v-slot:searchBody>
        <b-row class="mt-4">
          <b-col xs="12" sm="12" md="4">
            <ValidationProvider name="Designation Id" vid="designation_id">
              <b-form-group class="row" label-for="designation_id" slot-scope="{ valid, errors }">
                <template v-slot:label>
                  {{ $t('globalTrans.designation')}}
                </template>
                <b-form-select plain v-model="search.designation_id" :options="designationList"
                  id="designation_id" :state="errors[0] ? false : (valid ? true : null)">
                  <template v-slot:first>
                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                </b-form-select>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col xs="12" sm="12" md="4">
            <ValidationProvider name="Officer Name" vid="user_id">
              <b-form-group label-for="user_id" slot-scope="{ valid, errors }">
                <template v-slot:label>
                  {{ $t('exportTrophyCircular.employee') }}
                </template>
                <b-form-select plain v-model="search.user_id" :options="employeeList" id="user_id"
                  :state="errors[0] ? false : (valid ? true : null)">
                  <template v-slot:first>
                    <b-form-select-option :value=0>{{ userLoading ? 'Loading..' :
                      $t('globalTrans.select')}}</b-form-select-option>
                  </template>
                </b-form-select>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col xs="12" sm="12" md="4">
            <ValidationProvider name="Date From" vid="from_date" rules="" v-slot="{ errors }">
              <b-form-group label-for="from_date">
                <template v-slot:label>
                  {{ $t('externalTradeFair.date_from') }}
                </template>
                <date-picker id="from_date" v-model="search.from_date" class="form-control"
                  :placeholder="$t('globalTrans.select_date')" :state="errors[0] ? false : (valid ? true : null)"
                  :class="errors[0] ? 'is-invalid' : ''">
                </date-picker>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col xs="12" sm="12" md="4">
            <ValidationProvider name="Date To" vid="to_date" rules="" v-slot="{ errors }">
              <b-form-group label-for="to_date">
                <template v-slot:label>
                  {{ $t('externalTradeFair.date_to') }}
                </template>
                <date-picker id="to_date" v-model="search.to_date" class="form-control"
                  :placeholder="$t('globalTrans.select_date')" :state="errors[0] ? false : (valid ? true : null)"
                  :class="errors[0] ? 'is-invalid' : ''">
                </date-picker>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
        </b-row>
        <b-row class="text-right">
          <b-col>
            <b-button type="submit" variant="success"  @click="searchData" class="mr-2 btn-sm">{{$t('globalTrans.search')}}</b-button>
          </b-col>
        </b-row>
      </template>
      <!-- search section end -->
    </card>
    <b-row>
      <b-col md="12" v-if="showData">
        <body-card>
              <!-- table section start -->
              <template v-slot:headerTitle>
                <h4 class="card-title">{{ $t('tradeTariffReport.concern_person_report') }}</h4>
              </template>
              <template v-slot:headerAction>
              </template>
              <template v-slot:body>
                <b-overlay :show="loadingState">
                  <b-row class="text-right mt-3 mr-1">
                    <b-col>
                      <b-button @click="pdfExport" class="btn btn-success btn-md">
                        <i class="ri-file-pdf-line"></i> {{ $t('globalTrans.pdf') }}
                      </b-button>
                    </b-col>
                  </b-row>
                  <div style="border:2px solid;margin:10px;padding:10px">
                    <template>
                      <div class="text-black mb-4">
                        <ReportHeading />
                        <b-row class="mt-5">
                          <template v-if="datas.length">
                              <b-row v-for="(item, parentIndex) in datas" :key="parentIndex">
                                <b-col>
                                  <h6>{{ $t('exportTrophyCircular.employee') }} : {{ getEmployeeList(item.employee_id) }}</h6>
                                  <b-table-simple class="tg mt-3" bordered striped hover small caption-top responsive :emptyText="$t('globalTrans.noDataFound')">
                                    <b-thead>
                                      <b-tr>
                                        <b-th class="text-center" width="7%">{{ $t('globalTrans.sl_no') }}</b-th>
                                        <b-th class="text-center" width="20%">{{ $t('tradeTariffReport.beneficiary_name') }}</b-th>
                                        <b-th class="text-center" width="20%">{{ $t('concernManagement.mobile_number') }}</b-th>
                                        <b-th class="text-center" width="20%">{{ $t('concernManagement.address') }}</b-th>
                                        <b-th class="text-center" width="20%">{{ $t('concernManagement.subject') }}</b-th>
                                        <b-th class="text-center" width="20%">{{ $t('globalTrans.date') }}</b-th>
                                      </b-tr>
                                    </b-thead>
                                    <b-tbody>
                                        <b-tr v-for="(info, index) in item.beneficiary_info" :key="index">
                                          <b-td class="text-center">{{ $n(index+1) }}</b-td>
                                          <b-td class="text-center">{{ getConcentUserList(info.user_id) }}</b-td>
                                          <b-td class="text-center">{{ (currentLocale == 'en' ? '0' : '০') + getMobileNumber(info.user_id)}}</b-td>
                                          <b-td class="text-center">{{ getAddress(info.user_id) }}</b-td>
                                          <b-td class="text-center">{{ getSubject(info.subject_id) }}</b-td>
                                          <b-td class="text-center">{{ info.created_at | dateFormat }}</b-td>
                                        </b-tr>
                                    </b-tbody>
                                  </b-table-simple>
                                </b-col>
                              </b-row>
                            </template>
                            <template v-else>
                              <div class="text-center">
                                  {{ $t('globalTrans.noDataFound') }}
                                </div>
                            </template>
                        </b-row>
                      </div>
                    </template>
                  </div>
                </b-overlay>
              </template>
              <!-- table section end -->
        </body-card>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import ModalBaseMasterList from '@/mixins/list'
import RestApi, { tradeTariffServiceBaseUrl, authServiceBaseUrl } from '@/config/api_config'
import { concernPersonReportList, userListByDesignationApi } from '../../api/routes'
import ReportHeading from '../../pages/report-heading/ReportHeading.vue'
import { dateFormatBn, dateFormatEn, dateFormat } from '@/utils/fliter'
import Address from '@/utils/area-type-address'
export default {
  mixins: [ModalBaseMasterList],
  components: {
    ReportHeading
  },
  data () {
    return {
      tradeTariffServiceBaseUrl: tradeTariffServiceBaseUrl,
      dateFormat: dateFormat,
      dateFormatEn: dateFormatEn,
      dateFormatBn: dateFormatBn,
      userLoading: false,
      loader: false,
      userList: [],
      employeeList: [],
      search: {
        user_id: 0,
        designation_id: 0,
        from_date: '',
        to_date: ''
      },
      itemListGroup: [],
      datas: [],
      associationList: [],
      userEmployeeList: [],
      show: false,
      valid: '',
      Address: Address,
      showData: false
    }
  },
  created () {
  },
  computed: {
    designationList: function () {
      return this.$store.state.CommonService.commonObj.designationList.filter(item => item.org_id === 4)
    },
    currentLocale () {
      return this.$i18n.locale
    },
    fiscalYearList () {
      return this.$store.state.CommonService.commonObj.fiscalYearList.filter(item => item.status === 1)
    }
  },
   watch: {
    'search.designation_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
       this.getUserList(newVal)
      }
    }
  },
  methods: {
    async getUserList (designationId) {
      this.userLoading = true
        const result = await RestApi.getData(authServiceBaseUrl, `${userListByDesignationApi}/${designationId}`)
        this.userLoading = false
        if (result) {
          this.employeeList = result.map((obj, index) => {
            return Object.assign({}, obj, {
              value: obj.value,
              text: this.$i18n.locale === 'en' ? obj.text_en : obj.text_bn
            })
          })
        } else {
          this.employeeList = []
        }
    },
    searchData () {
      this.loadData()
    },
    getSubject (id) {
      const data = this.$store.state.TradeTariffService.commonObj.subjectList.find(item => item.value === id)
      if (typeof data !== 'undefined') {
        return this.$i18n.locale === 'bn' ? data?.text_bn : data?.text_en
      } else {
        return ''
      }
    },
    getOfficerName (id) {
      const data = this.userList.find(item => item.value === id)
      if (typeof data !== 'undefined') {
        return this.$i18n.locale === 'bn' ? data?.text_bn : data?.text_en
      } else {
        return ''
      }
    },
    async loadData () {
      this.showData = true
      this.loader = true
      const params = Object.assign({}, this.search)
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      const result = await RestApi.getData(tradeTariffServiceBaseUrl, concernPersonReportList, params)
      if (result.success) {
        this.userList = result.userList
        this.userEmployeeList = result.userEmployeeList
        this.datas = result.data
      } else {
        this.datas = []
      }
      this.loader = false
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    },
    getAddress (userId) {
      const Obj = this.userList.find(el => el.user_id === parseInt(userId))
      const address = Address.getPermanentAddress(Obj)
      return address
    },
    getConcentUserList (userId) {
      const obj = this.userList.find(el => el.user_id === userId)
      if (typeof obj !== 'undefined') {
        return this.currentLocale === 'en' ? obj.name : obj.name_bn
      } else {
        return ''
      }
    },
    getEmployeeList (id) {
      const data = this.userEmployeeList.find(item => item.value === id)
      if (typeof data !== 'undefined') {
        return this.$i18n.locale === 'bn' ? data?.text_bn : data?.text_en
      } else {
        return ''
      }
    },
    getMobileNumber (userId) {
      const obj = this.userList.find(el => el.user_id === userId)
      if (typeof obj !== 'undefined') {
      return this.$n(obj.mobile, { useGrouping: false })
      } else {
        return ''
      }
    },
    async pdfExport () {
      this.loader = true
      this.search.potential = true
      const params = Object.assign({}, this.search, { request_type: 'pdf', local: this.$i18n.locale, org_id: 7 })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      const result = await RestApi.getPdfData(tradeTariffServiceBaseUrl, concernPersonReportList, params)
      var blob = new Blob([result], {
        type: 'application/pdf'
      })
      var url = window.URL.createObjectURL(blob)
      window.open(url).print()
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      this.loader = false
    }
  }
}
</script>
<style>
/* @import '../../style.css' */
</style>
